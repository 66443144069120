import { default as _91_46_46_46identifier_93CjfuAh68YpMeta } from "/app/apps/online-store/pages/[...identifier].vue?macro=true";
import { default as indexA7ZANC2TRLMeta } from "/app/apps/online-store/pages/about-us/index.vue?macro=true";
import { default as _91addressId_93Gx0aVmxKVpMeta } from "/app/apps/online-store/pages/address-book/[addressId].vue?macro=true";
import { default as indexsFNPIdczDIMeta } from "/app/apps/online-store/pages/address-book/index.vue?macro=true";
import { default as all_45productsEUgDmNuZSfMeta } from "/app/apps/online-store/pages/all-products.vue?macro=true";
import { default as _91id_93aq3WzFtiVIMeta } from "/app/apps/online-store/pages/blogs/[id].vue?macro=true";
import { default as indexxQ7R3SGuUtMeta } from "/app/apps/online-store/pages/blogs/index.vue?macro=true";
import { default as _91handle_93aYPwLIPnFrMeta } from "/app/apps/online-store/pages/brands/[handle].vue?macro=true";
import { default as indexNxstJ0YWMlMeta } from "/app/apps/online-store/pages/brands/index.vue?macro=true";
import { default as index9UBu5tlI1HMeta } from "/app/apps/online-store/pages/campus-program/index.vue?macro=true";
import { default as index8rBVcAOwxiMeta } from "/app/apps/online-store/pages/campus-program/onboarding/index.vue?macro=true";
import { default as signupZlLN7jePXrMeta } from "/app/apps/online-store/pages/campus-program/onboarding/signup.vue?macro=true";
import { default as verifyxpkYHBFhJPMeta } from "/app/apps/online-store/pages/campus-program/verify.vue?macro=true";
import { default as indexQFjTqhgKSTMeta } from "/app/apps/online-store/pages/cart/index.vue?macro=true";
import { default as index31XHBbjFgXMeta } from "/app/apps/online-store/pages/checkout-invalid/index.vue?macro=true";
import { default as indexiKQR3BHrnmMeta } from "/app/apps/online-store/pages/checkout-thankyou/index.vue?macro=true";
import { default as _91chash_936n5nM5tf0lMeta } from "/app/apps/online-store/pages/checkout-v2/[chash].vue?macro=true";
import { default as index2cv5TurP4xMeta } from "/app/apps/online-store/pages/checkout-verify/index.vue?macro=true";
import { default as _91chash_93O5EQ5kiydOMeta } from "/app/apps/online-store/pages/checkout/[chash].vue?macro=true";
import { default as index0OlZvUF8xbMeta } from "/app/apps/online-store/pages/checkoutv2-thankyou/index.vue?macro=true";
import { default as _91handle_93IUNchyHTM8Meta } from "/app/apps/online-store/pages/drops/[handle].vue?macro=true";
import { default as indexrEKZN9FadnMeta } from "/app/apps/online-store/pages/help-center/index.vue?macro=true";
import { default as indexr25RsIjLx7Meta } from "/app/apps/online-store/pages/help-center/policies/index.vue?macro=true";
import { default as index3meRBY1jOxMeta } from "/app/apps/online-store/pages/login/index.vue?macro=true";
import { default as _91handle_93FgxYWwB2eRMeta } from "/app/apps/online-store/pages/nodes/[handle].vue?macro=true";
import { default as indexAf35WvtKuDMeta } from "/app/apps/online-store/pages/onboarding/index.vue?macro=true";
import { default as _91orderNumber_93v91e3cRRMZMeta } from "/app/apps/online-store/pages/orders/[orderNumber].vue?macro=true";
import { default as indexb3pmF0s0U2Meta } from "/app/apps/online-store/pages/orders/index.vue?macro=true";
import { default as previewHRw6myyLrwMeta } from "/app/apps/online-store/pages/preview.vue?macro=true";
import { default as indexITeBCcTW7sMeta } from "/app/apps/online-store/pages/privacy-policy/index.vue?macro=true";
import { default as _91handle_937R8otOfi0cMeta } from "/app/apps/online-store/pages/products/[handle].vue?macro=true";
import { default as indexFlC9yaJiHeMeta } from "/app/apps/online-store/pages/profile/index.vue?macro=true";
import { default as indexKg6WnloHvbMeta } from "/app/apps/online-store/pages/refund-policy/index.vue?macro=true";
import { default as indexYAMPdhqcXZMeta } from "/app/apps/online-store/pages/return-policy/index.vue?macro=true";
import { default as indexnpQxPJjSSKMeta } from "/app/apps/online-store/pages/rewards-faq/index.vue?macro=true";
import { default as indexRxXCaD3VRoMeta } from "/app/apps/online-store/pages/rewards-history/index.vue?macro=true";
import { default as indexMO8eAKAzdhMeta } from "/app/apps/online-store/pages/rewards/index.vue?macro=true";
import { default as indexRPrnbqS67SMeta } from "/app/apps/online-store/pages/search/index.vue?macro=true";
import { default as _91handle_93jTw78fMpgmMeta } from "/app/apps/online-store/pages/set/[handle].vue?macro=true";
import { default as indexzJs3tPZw4YMeta } from "/app/apps/online-store/pages/shipping-delivery/index.vue?macro=true";
import { default as indexUfiWw7e4nBMeta } from "/app/apps/online-store/pages/terms-conditions/index.vue?macro=true";
import { default as _91wishlistHandle_9352BMRIsZnwMeta } from "/app/apps/online-store/pages/wishlist/[wishlistHandle].vue?macro=true";
import { default as nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657GOVEgAVtlwMeta } from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_oahynstmro4zb7odzpsaaghv5m/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91_46_46_46identifier_93CjfuAh68YpMeta?.name ?? "identifier",
    path: _91_46_46_46identifier_93CjfuAh68YpMeta?.path ?? "/:identifier(.*)*",
    meta: _91_46_46_46identifier_93CjfuAh68YpMeta || {},
    alias: _91_46_46_46identifier_93CjfuAh68YpMeta?.alias || [],
    redirect: _91_46_46_46identifier_93CjfuAh68YpMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/[...identifier].vue").then(m => m.default || m)
  },
  {
    name: indexA7ZANC2TRLMeta?.name ?? "about-us",
    path: indexA7ZANC2TRLMeta?.path ?? "/about-us",
    meta: indexA7ZANC2TRLMeta || {},
    alias: indexA7ZANC2TRLMeta?.alias || [],
    redirect: indexA7ZANC2TRLMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: _91addressId_93Gx0aVmxKVpMeta?.name ?? "address-book-addressId",
    path: _91addressId_93Gx0aVmxKVpMeta?.path ?? "/address-book/:addressId()",
    meta: _91addressId_93Gx0aVmxKVpMeta || {},
    alias: _91addressId_93Gx0aVmxKVpMeta?.alias || [],
    redirect: _91addressId_93Gx0aVmxKVpMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/address-book/[addressId].vue").then(m => m.default || m)
  },
  {
    name: indexsFNPIdczDIMeta?.name ?? "address-book",
    path: indexsFNPIdczDIMeta?.path ?? "/address-book",
    meta: indexsFNPIdczDIMeta || {},
    alias: indexsFNPIdczDIMeta?.alias || [],
    redirect: indexsFNPIdczDIMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/address-book/index.vue").then(m => m.default || m)
  },
  {
    name: all_45productsEUgDmNuZSfMeta?.name ?? "all-products",
    path: all_45productsEUgDmNuZSfMeta?.path ?? "/all-products",
    meta: all_45productsEUgDmNuZSfMeta || {},
    alias: all_45productsEUgDmNuZSfMeta?.alias || [],
    redirect: all_45productsEUgDmNuZSfMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/all-products.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aq3WzFtiVIMeta?.name ?? "blogs-id",
    path: _91id_93aq3WzFtiVIMeta?.path ?? "/blogs/:id()",
    meta: _91id_93aq3WzFtiVIMeta || {},
    alias: _91id_93aq3WzFtiVIMeta?.alias || [],
    redirect: _91id_93aq3WzFtiVIMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/blogs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxQ7R3SGuUtMeta?.name ?? "blogs",
    path: indexxQ7R3SGuUtMeta?.path ?? "/blogs",
    meta: indexxQ7R3SGuUtMeta || {},
    alias: indexxQ7R3SGuUtMeta?.alias || [],
    redirect: indexxQ7R3SGuUtMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93aYPwLIPnFrMeta?.name ?? "brands-handle",
    path: _91handle_93aYPwLIPnFrMeta?.path ?? "/brands/:handle()",
    meta: _91handle_93aYPwLIPnFrMeta || {},
    alias: _91handle_93aYPwLIPnFrMeta?.alias || [],
    redirect: _91handle_93aYPwLIPnFrMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/brands/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexNxstJ0YWMlMeta?.name ?? "brands",
    path: indexNxstJ0YWMlMeta?.path ?? "/brands",
    meta: indexNxstJ0YWMlMeta || {},
    alias: indexNxstJ0YWMlMeta?.alias || [],
    redirect: indexNxstJ0YWMlMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: index9UBu5tlI1HMeta?.name ?? "campus-program",
    path: index9UBu5tlI1HMeta?.path ?? "/campus-program",
    meta: index9UBu5tlI1HMeta || {},
    alias: index9UBu5tlI1HMeta?.alias || [],
    redirect: index9UBu5tlI1HMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/campus-program/index.vue").then(m => m.default || m)
  },
  {
    name: index8rBVcAOwxiMeta?.name ?? "campus-program-onboarding",
    path: index8rBVcAOwxiMeta?.path ?? "/campus-program/onboarding",
    meta: index8rBVcAOwxiMeta || {},
    alias: index8rBVcAOwxiMeta?.alias || [],
    redirect: index8rBVcAOwxiMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/campus-program/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: signupZlLN7jePXrMeta?.name ?? "campus-program-onboarding-signup",
    path: signupZlLN7jePXrMeta?.path ?? "/campus-program/onboarding/signup",
    meta: signupZlLN7jePXrMeta || {},
    alias: signupZlLN7jePXrMeta?.alias || [],
    redirect: signupZlLN7jePXrMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/campus-program/onboarding/signup.vue").then(m => m.default || m)
  },
  {
    name: verifyxpkYHBFhJPMeta?.name ?? "campus-program-verify",
    path: verifyxpkYHBFhJPMeta?.path ?? "/campus-program/verify",
    meta: verifyxpkYHBFhJPMeta || {},
    alias: verifyxpkYHBFhJPMeta?.alias || [],
    redirect: verifyxpkYHBFhJPMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/campus-program/verify.vue").then(m => m.default || m)
  },
  {
    name: indexQFjTqhgKSTMeta?.name ?? "cart",
    path: indexQFjTqhgKSTMeta?.path ?? "/cart",
    meta: indexQFjTqhgKSTMeta || {},
    alias: indexQFjTqhgKSTMeta?.alias || [],
    redirect: indexQFjTqhgKSTMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: index31XHBbjFgXMeta?.name ?? "checkout-invalid",
    path: index31XHBbjFgXMeta?.path ?? "/checkout-invalid",
    meta: index31XHBbjFgXMeta || {},
    alias: index31XHBbjFgXMeta?.alias || [],
    redirect: index31XHBbjFgXMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/checkout-invalid/index.vue").then(m => m.default || m)
  },
  {
    name: indexiKQR3BHrnmMeta?.name ?? "checkout-thankyou",
    path: indexiKQR3BHrnmMeta?.path ?? "/checkout-thankyou",
    meta: indexiKQR3BHrnmMeta || {},
    alias: indexiKQR3BHrnmMeta?.alias || [],
    redirect: indexiKQR3BHrnmMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/checkout-thankyou/index.vue").then(m => m.default || m)
  },
  {
    name: _91chash_936n5nM5tf0lMeta?.name ?? "checkout-v2-chash",
    path: _91chash_936n5nM5tf0lMeta?.path ?? "/checkout-v2/:chash()",
    meta: _91chash_936n5nM5tf0lMeta || {},
    alias: _91chash_936n5nM5tf0lMeta?.alias || [],
    redirect: _91chash_936n5nM5tf0lMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/checkout-v2/[chash].vue").then(m => m.default || m)
  },
  {
    name: index2cv5TurP4xMeta?.name ?? "checkout-verify",
    path: index2cv5TurP4xMeta?.path ?? "/checkout-verify",
    meta: index2cv5TurP4xMeta || {},
    alias: index2cv5TurP4xMeta?.alias || [],
    redirect: index2cv5TurP4xMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/checkout-verify/index.vue").then(m => m.default || m)
  },
  {
    name: _91chash_93O5EQ5kiydOMeta?.name ?? "checkout-chash",
    path: _91chash_93O5EQ5kiydOMeta?.path ?? "/checkout/:chash()",
    meta: _91chash_93O5EQ5kiydOMeta || {},
    alias: _91chash_93O5EQ5kiydOMeta?.alias || [],
    redirect: _91chash_93O5EQ5kiydOMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/checkout/[chash].vue").then(m => m.default || m)
  },
  {
    name: index0OlZvUF8xbMeta?.name ?? "checkoutv2-thankyou",
    path: index0OlZvUF8xbMeta?.path ?? "/checkoutv2-thankyou",
    meta: index0OlZvUF8xbMeta || {},
    alias: index0OlZvUF8xbMeta?.alias || [],
    redirect: index0OlZvUF8xbMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/checkoutv2-thankyou/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93IUNchyHTM8Meta?.name ?? "drops-handle",
    path: _91handle_93IUNchyHTM8Meta?.path ?? "/drops/:handle()",
    meta: _91handle_93IUNchyHTM8Meta || {},
    alias: _91handle_93IUNchyHTM8Meta?.alias || [],
    redirect: _91handle_93IUNchyHTM8Meta?.redirect,
    component: () => import("/app/apps/online-store/pages/drops/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexrEKZN9FadnMeta?.name ?? "help-center",
    path: indexrEKZN9FadnMeta?.path ?? "/help-center",
    meta: indexrEKZN9FadnMeta || {},
    alias: indexrEKZN9FadnMeta?.alias || [],
    redirect: indexrEKZN9FadnMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexr25RsIjLx7Meta?.name ?? "help-center-policies",
    path: indexr25RsIjLx7Meta?.path ?? "/help-center/policies",
    meta: indexr25RsIjLx7Meta || {},
    alias: indexr25RsIjLx7Meta?.alias || [],
    redirect: indexr25RsIjLx7Meta?.redirect,
    component: () => import("/app/apps/online-store/pages/help-center/policies/index.vue").then(m => m.default || m)
  },
  {
    name: index3meRBY1jOxMeta?.name ?? "login",
    path: index3meRBY1jOxMeta?.path ?? "/login",
    meta: index3meRBY1jOxMeta || {},
    alias: index3meRBY1jOxMeta?.alias || [],
    redirect: index3meRBY1jOxMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93FgxYWwB2eRMeta?.name ?? "nodes-handle",
    path: _91handle_93FgxYWwB2eRMeta?.path ?? "/nodes/:handle()",
    meta: _91handle_93FgxYWwB2eRMeta || {},
    alias: _91handle_93FgxYWwB2eRMeta?.alias || [],
    redirect: _91handle_93FgxYWwB2eRMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/nodes/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexAf35WvtKuDMeta?.name ?? "onboarding",
    path: indexAf35WvtKuDMeta?.path ?? "/onboarding",
    meta: indexAf35WvtKuDMeta || {},
    alias: indexAf35WvtKuDMeta?.alias || [],
    redirect: indexAf35WvtKuDMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderNumber_93v91e3cRRMZMeta?.name ?? "orders-orderNumber",
    path: _91orderNumber_93v91e3cRRMZMeta?.path ?? "/orders/:orderNumber()",
    meta: _91orderNumber_93v91e3cRRMZMeta || {},
    alias: _91orderNumber_93v91e3cRRMZMeta?.alias || [],
    redirect: _91orderNumber_93v91e3cRRMZMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/orders/[orderNumber].vue").then(m => m.default || m)
  },
  {
    name: indexb3pmF0s0U2Meta?.name ?? "orders",
    path: indexb3pmF0s0U2Meta?.path ?? "/orders",
    meta: indexb3pmF0s0U2Meta || {},
    alias: indexb3pmF0s0U2Meta?.alias || [],
    redirect: indexb3pmF0s0U2Meta?.redirect,
    component: () => import("/app/apps/online-store/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: previewHRw6myyLrwMeta?.name ?? "preview",
    path: previewHRw6myyLrwMeta?.path ?? "/preview",
    meta: previewHRw6myyLrwMeta || {},
    alias: previewHRw6myyLrwMeta?.alias || [],
    redirect: previewHRw6myyLrwMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: indexITeBCcTW7sMeta?.name ?? "privacy-policy",
    path: indexITeBCcTW7sMeta?.path ?? "/privacy-policy",
    meta: indexITeBCcTW7sMeta || {},
    alias: indexITeBCcTW7sMeta?.alias || [],
    redirect: indexITeBCcTW7sMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_937R8otOfi0cMeta?.name ?? "products-handle",
    path: _91handle_937R8otOfi0cMeta?.path ?? "/products/:handle()",
    meta: _91handle_937R8otOfi0cMeta || {},
    alias: _91handle_937R8otOfi0cMeta?.alias || [],
    redirect: _91handle_937R8otOfi0cMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/products/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexFlC9yaJiHeMeta?.name ?? "profile",
    path: indexFlC9yaJiHeMeta?.path ?? "/profile",
    meta: indexFlC9yaJiHeMeta || {},
    alias: indexFlC9yaJiHeMeta?.alias || [],
    redirect: indexFlC9yaJiHeMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKg6WnloHvbMeta?.name ?? "refund-policy",
    path: indexKg6WnloHvbMeta?.path ?? "/refund-policy",
    meta: indexKg6WnloHvbMeta || {},
    alias: indexKg6WnloHvbMeta?.alias || [],
    redirect: indexKg6WnloHvbMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/refund-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexYAMPdhqcXZMeta?.name ?? "return-policy",
    path: indexYAMPdhqcXZMeta?.path ?? "/return-policy",
    meta: indexYAMPdhqcXZMeta || {},
    alias: indexYAMPdhqcXZMeta?.alias || [],
    redirect: indexYAMPdhqcXZMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/return-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexnpQxPJjSSKMeta?.name ?? "rewards-faq",
    path: indexnpQxPJjSSKMeta?.path ?? "/rewards-faq",
    meta: indexnpQxPJjSSKMeta || {},
    alias: indexnpQxPJjSSKMeta?.alias || [],
    redirect: indexnpQxPJjSSKMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/rewards-faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexRxXCaD3VRoMeta?.name ?? "rewards-history",
    path: indexRxXCaD3VRoMeta?.path ?? "/rewards-history",
    meta: indexRxXCaD3VRoMeta || {},
    alias: indexRxXCaD3VRoMeta?.alias || [],
    redirect: indexRxXCaD3VRoMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/rewards-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexMO8eAKAzdhMeta?.name ?? "rewards",
    path: indexMO8eAKAzdhMeta?.path ?? "/rewards",
    meta: indexMO8eAKAzdhMeta || {},
    alias: indexMO8eAKAzdhMeta?.alias || [],
    redirect: indexMO8eAKAzdhMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/rewards/index.vue").then(m => m.default || m)
  },
  {
    name: indexRPrnbqS67SMeta?.name ?? "search",
    path: indexRPrnbqS67SMeta?.path ?? "/search",
    meta: indexRPrnbqS67SMeta || {},
    alias: indexRPrnbqS67SMeta?.alias || [],
    redirect: indexRPrnbqS67SMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93jTw78fMpgmMeta?.name ?? "set-handle",
    path: _91handle_93jTw78fMpgmMeta?.path ?? "/set/:handle()",
    meta: _91handle_93jTw78fMpgmMeta || {},
    alias: _91handle_93jTw78fMpgmMeta?.alias || [],
    redirect: _91handle_93jTw78fMpgmMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/set/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexzJs3tPZw4YMeta?.name ?? "shipping-delivery",
    path: indexzJs3tPZw4YMeta?.path ?? "/shipping-delivery",
    meta: indexzJs3tPZw4YMeta || {},
    alias: indexzJs3tPZw4YMeta?.alias || [],
    redirect: indexzJs3tPZw4YMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/shipping-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: indexUfiWw7e4nBMeta?.name ?? "terms-conditions",
    path: indexUfiWw7e4nBMeta?.path ?? "/terms-conditions",
    meta: indexUfiWw7e4nBMeta || {},
    alias: indexUfiWw7e4nBMeta?.alias || [],
    redirect: indexUfiWw7e4nBMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: _91wishlistHandle_9352BMRIsZnwMeta?.name ?? "wishlist-wishlistHandle",
    path: _91wishlistHandle_9352BMRIsZnwMeta?.path ?? "/wishlist/:wishlistHandle()",
    meta: _91wishlistHandle_9352BMRIsZnwMeta || {},
    alias: _91wishlistHandle_9352BMRIsZnwMeta?.alias || [],
    redirect: _91wishlistHandle_9352BMRIsZnwMeta?.redirect,
    component: () => import("/app/apps/online-store/pages/wishlist/[wishlistHandle].vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657GOVEgAVtlwMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657GOVEgAVtlwMeta?.path ?? "/sitemap.xml",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657GOVEgAVtlwMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657GOVEgAVtlwMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657GOVEgAVtlwMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_ioredis@_oahynstmro4zb7odzpsaaghv5m/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]