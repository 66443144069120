import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';
import { Type, type Static } from '@sinclair/typebox';
import { useLoganStore } from '~/stores/logan.ts';
import { validatePhoneNumber } from '~/utils/checkout.ts';

// validation throws error or is one time
// not dynamic
export const LoganSchema = toTypedSchema(
	z
		.object({
			phoneCountryCode: z.string(),
			phoneNumber: z
				.string()

				.optional(),
			email: z.string().optional(),

			otpCode: z.optional(z.string()),
			usingEmail: z.boolean(),
			phonePlaceholderPattern: z.string().optional(),
			phoneRegexPattern: z.string().optional(),
			subscribeToNewsLetter: z.boolean(),
		})
		.refine(
			(data) => {
				const LoganStore = useLoganStore();
				if (data.usingEmail && data.email !== undefined) {
					return LoganStore.verifyEmail(data.email);
				}
				return true;
			},
			{
				message: 'Please enter a valid email address.',
				path: ['email'],
			},
		)
		.refine(
			(data) => {
				if (!data.usingEmail) {
					if (
						!validatePhoneNumber(data.phoneNumber!, data.phoneRegexPattern!)
					) {
						return false;
					}

					return true;
				}
				return true;
			},
			{
				message: 'Please enter a valid Phone number.',
				path: ['phoneNumber'],
			},
		),
);

export const NullString = Type.Union([Type.Null(), Type.String()]);
export const NullNumber = Type.Union([Type.Null(), Type.Number()]);
export const NullArray = Type.Union([Type.Null(), Type.Array(Type.String())]);
export const OtpSendSchema = Type.Object({
	logan: Type.Object({
		logan_id: NullNumber,
		has_existing_customer: Type.Optional(Type.Boolean()),
	}),
	message: Type.String(),
});

export const Address = Type.Object({
	id: Type.Number(),
	user_id: Type.Number(),
	phone: NullString,
	first_name: NullString,
	last_name: NullString,
	address1: Type.String(),
	address2: NullString,
	country: Type.String(),
	city: Type.String(),
	zip: NullString,
	postal_code: NullString,
	province: NullString,
	default: Type.Boolean(),
	is_billing: Type.Boolean(),
	country_code: Type.Optional(NullString),
	phone_prefix: Type.Optional(NullString),
	phone_country_code: Type.Optional(NullString),
});

export const LoganResponseSchema = Type.Object({
	message: Type.String(),
	shopify_id: NullNumber,
	permissions: Type.Array(NullString),
	access_token: Type.String(),
	refresh_token: Type.String(),
	addresses: Type.Optional(Type.Array(Address)),
	default_address: Type.Optional(
		Type.Union([Address, Type.String(), Type.Null()]),
	),
	user: Type.Object({
		id: Type.Number(),
		email: NullString,
		phone: NullString,
		first_name: NullString,
		last_name: NullString,
		gender: NullString,
		date_of_birth: NullString,
		created_at: Type.String(),
		tags: NullArray,
		is_hvc: Type.Boolean(),
		hvc_tier_type: NullString,
		deleted: Type.Boolean(),
		store_id: NullNumber,
		password: NullString,
		// cartID: NullString,
	}),
});

export const LoganMergerResponseSchema = Type.Object({
	message: Type.String(),
	shopify_id: NullNumber,
	permissions: Type.Array(NullString),
	access_token: Type.String(),
	refresh_token: Type.String(),
	addresses: Type.Array(Address),
	default_address: Type.Union([Address, Type.String(), Type.Null()]),
	user: Type.Object({
		id: Type.Number(),
		email: NullString,
		phone: NullString,
		first_name: NullString,
		last_name: NullString,
		gender: NullString,
		date_of_birth: NullString,
		created_at: Type.String(),
		tags: NullArray,
		is_hvc: Type.Boolean(),
		hvc_tier_type: NullString,
		deleted: Type.Boolean(),
		store_id: NullNumber,
		password: NullString,
		// cartID: NullString,
	}),
});

export const DeleteResponseSchema = Type.Object({
	msg: Type.String(),
	code: Type.Number(),
	data: Type.Object({
		message: Type.String(),
	}),
});
export const UpdateInfoSchema = Type.Object({
	message: Type.String(),
	user: Type.Object({
		logan_action: Type.String(),
		last_name: Type.String(),
		id: Type.Number(),
		email: Type.Optional(Type.Union([Type.String(), Type.Null()])),
		gender: Type.Optional(Type.Union([Type.String(), Type.Null()])),
		created_at: Type.String(),
		tags: NullArray,
		is_hvc: Type.Boolean(),
		store_id: NullNumber,
		first_name: Type.String(),
		phone: Type.Optional(Type.Union([Type.String(), Type.Null()])),
		date_of_birth: Type.Optional(Type.Union([Type.String(), Type.Null()])),
		hvc_tier_type: NullString,
		deleted: Type.Boolean(),
		password: NullString,
	}),
});

export type AddressType = Static<typeof Address>;
