import { defineNuxtPlugin } from 'nuxt/app';
import * as Sentry from '@sentry/vue';
import { useRouter } from 'vue-router';
import { useGlobalStore } from '~/stores/global';
import { STORE_TYPE } from '~/utils/constants';

export default defineNuxtPlugin(async (nuxt) => {
	const globalStore = useGlobalStore();
	const router = useRouter();
	const config = useRuntimeConfig();
	Sentry.init({
		app: nuxt.vueApp,
		dsn: config.public.sentryDsn,
		integrations: [Sentry.browserTracingIntegration({ router })],
		// Performance Monitoring
		tracesSampler: ({ name }) => {
			if (name.includes('checkoutv2')) return 1;
			if (name.includes('checkout')) return 1;
			return 0.1;
		},
		// // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets:
			globalStore.store.store_type === STORE_TYPE.MARKETPLACE
				? ['dev-v2.laam.pk', 'shop.laam.pk', 'laam.pk']
				: undefined,
		// // Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		ignoreErrors: [
			'Non-Error promise rejection captured',
			'input must be a string (received string: "")',
			'Failed to fetch',
			'Request aborted',
			'Unable to preload CSS for',
			'Error fetching initial product data',
			'Failed to verify product data',
			'free_shipping_feature config is not defined',
			'config.title is undefined',
			'Page not found',
			'route.fullPath is not defined',
			'Network Error',
			'Load failed',
			'Importing a module script failed.',
			"Can't find variable: Notification", // eslint-disable-line
			"Cannot read properties of null (reading 'transition')", // eslint-disable-line
			'Event `Event` (type=error) captured as promise rejection', // eslint-disable-line
			'Maximum call stack size exceeded',
			'Failed to call function after retries even after initialization',
			'Enter OTP request failed',
			// eslint-disable-next-line quotes
			"Cannot read properties of undefined (reading 'initialised')",
			'/_nuxt/builds/', // ignoreError does partial matching, it will ignore errors containing this
		],
	});
});
