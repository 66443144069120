// @ts-nocheck
import { BaseTransformer } from '../base-transformer';
import type { IAdapter, InitArguments } from '../event-client';
import type { EventPayloads, EventTypes, LoginPayload } from '../events';
import { SHA256 } from 'crypto-js';

const subscribedEvents: Array<EventTypes> = [
	'view-cart',
	'add-to-cart',
	'page-view',
	'remove-from-cart',
	'proceed-to-checkout',
	'checkout-page-landing',
	'checkout-payment-method-select',
	'checkout-thankyou',
	'purchase',
];

class MetaPixelTransformer {
	static transformPurchaseEvent(
		data: EventPayloads['purchase'],
	): Record<string, string | number> {
		// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
		const { lineItems, ...rest } = data;
		return {
			...rest,
			email: SHA256(data.email).toString(),
			phone: SHA256(data.phoneNumber).toString(),
		};
	}
}

const TransformerMap = {
	'add-to-cart': BaseTransformer.transformAddToCartEvent,
	'remove-from-cart': BaseTransformer.transformRemoveFromCartEvent,
	'proceed-to-checkout': BaseTransformer.transformProceedToCheckoutEvent,
	purchase: MetaPixelTransformer.transformPurchaseEvent,
};

export class MetaPixelAdapter implements IAdapter {
	name: string = 'metapixel';
	queue: Array<{ name: EventTypes; data: EventPayloads[EventTypes] }> = [];
	isInitialized = false;

	async init(arg: InitArguments['metapixel']): Promise<void> {
		// @ts-ignore
		(function (
			f: Window,
			b: Document,
			e: string,
			v: string,
			n: any,
			t: any,
			s: any,
		) {
			if (f.fbq) return;
			n = f.fbq = function (...args: any[]) {
				n!.callMethod ? n!.callMethod.apply(n, args) : n!.queue.push(args);
			} as any;
			// @ts-ignore
			if (!f._fbq) f._fbq = n;
			n!.push = n;
			n!.loaded = !0;
			n!.version = '2.0';
			n!.queue = [];
			t = b.createElement(e)!;
			t!.async = !0;
			t!.src = v;

			// Check if there are any script tags in the document
			const existingScripts = b.getElementsByTagName(e);
			if (existingScripts.length > 0) {
				s = existingScripts[0];
				s.parentNode?.insertBefore(t, s);
			} else {
				// If no script tags exist, append to head or body
				(b.head || b.body).appendChild(t);
			}
		})(
			window,
			document,
			'script',
			'https://connect.facebook.net/en_US/fbevents.js',
		);
		// @ts-ignore

		if (window.fbq) {
			window.fbq('init', arg.pixelId[0]);
			if (arg.pixelId.length > 1) {
				window.fbq('init', arg.pixelId[1]);
			}
		}

		this.isInitialized = true;
	}

	sendEvent<T extends EventTypes>(name: T, data: EventPayloads[T]): void {
		if (!subscribedEvents.includes(name)) {
			return;
		}
		if (!this.isInitialized) {
			this.queue.push({ name, data });
			return;
		}
		this.queue.push({ name, data });

		this.queue.forEach((event) => {
			// @ts-ignore
			const { data, name } = TransformerMap[event.name]
				? TransformerMap[event.name](event.data)
				: { data: event.data, name: event.name };
			window.fbq('track', name ?? event.name, data);
		});
	}
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	login(_: LoginPayload) {
		// no-op
	}
	logout(): void {
		// no-op
	}
}
