import type { Country } from '~/components/checkout/types.ts';
import { FORM_FIELDS } from '~/utils/enums.ts';
import { type DynamicField } from '~/types/Checkout';

const autoCompleteList = [
	'au',
	'us',
	'gb',
	'de',
	'ae',
	'ca',
	'sa',
	'hk',
	'no',
	'sg',
	'qa',
	'kw',
	'mu',
	'my',
];

const fieldsToExclude = [
	'country',
	'phone',
	'firstName',
	'lastName',
	'company',
];

const formatProvinceKey = (str: string) => {
	return str
		.replace(/_/g, ' ')
		.toLowerCase()
		.replace(/\b\w/g, (char) => char.toUpperCase());
};

const capitalizeFirstWord = (str: string) => {
	return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const structureDynamicAddressFields = (
	fields: string[],
	country: Country,
	countryCode: string,
	dynamicFields: Ref<DynamicField[]>,
	setFieldValue: (
		fieldName: string,
		value: any,
		shouldValidate: boolean,
	) => void,
	billingAddress: boolean,
	codeChanged: boolean,
) => {
	if (fields.length > 0) {
		//setting province and zip to null initially to not validate if they are not available in the fields
		setFieldValue(
			billingAddress ? 'billingAddress.province' : 'province',
			null,
			false,
		);

		setFieldValue(billingAddress ? 'billingAddress.zip' : 'zip', null, false);

		fields.forEach((field) => {
			if (!fieldsToExclude.includes(field)) {
				switch (field) {
					case FORM_FIELDS.PROVINCE: {
						setFieldValue(
							billingAddress ? 'billingAddress.province' : 'province',
							'',
							false,
						);

						if (country.zones!.length == 0) {
							dynamicFields.value.push({
								fieldLabel: formatProvinceKey(country.provinceKey),
								fieldName: field,
								type: 'text',
								value: null,
							});
						} else {
							dynamicFields.value.push({
								fieldLabel: formatProvinceKey(country.provinceKey),
								fieldName: field,
								type: 'select',
								value: country.zones,
							});
						}

						return;
					}
					case FORM_FIELDS.CITY: {
						setFieldValue(
							billingAddress ? 'billingAddress.city' : 'city',
							'',
							false,
						);
						if (countryCode === 'PK') {
							dynamicFields.value.push({
								fieldLabel: country.labels[field] ?? capitalizeFirstWord(field),
								fieldName: field,
								type: 'city-dropdown',
								value: null,
							});
						} else {
							dynamicFields.value.push({
								fieldLabel: country.labels[field] ?? capitalizeFirstWord(field),
								fieldName: field,
								type: 'text',
								value: null,
							});
						}
						return;
					}
					default: {
						if (codeChanged || field !== 'address1' || billingAddress) {
							setFieldValue(
								billingAddress ? `billingAddress.${field}` : field,
								'',
								false,
							);
						}

						dynamicFields.value.push({
							fieldLabel: country.labels[field] ?? capitalizeFirstWord(field),
							fieldName: field,
							type: 'text',
							value: null,
						});
						return;
					}
				}
			}
		});
	}
};

export {
	autoCompleteList,
	fieldsToExclude,
	capitalizeFirstWord,
	formatProvinceKey,
	structureDynamicAddressFields,
};

export interface SessionToken {
	[key: string]: string;
}
