import axios from 'axios';
import type {
	CathodeEventPayload,
	CathodeImpressionsPayload,
	CathodeEventsMetaData,
	CathodeEventsMetaDataTypes,
	UserObjectType,
	CathodeImpressionsMetaDataTypes,
	CathodeImpressionsMetaData,
} from './events';

export class CathodeEventClient {
	private readonly baseUrl: string;
	private readonly userObject: UserObjectType;

	eventsQueue: CathodeEventPayload[] = [];
	impressionsQueue: CathodeImpressionsPayload[] = [];
	eventWindow = 5000;
	apiWorker: Worker | null = null;

	constructor(baseUrl: string, userObject: UserObjectType) {
		this.baseUrl = baseUrl;
		this.userObject = userObject;

		this.initializeQueue();
	}

	// main function that sends the events
	async sendEvent(events: CathodeEventPayload[] | CathodeImpressionsPayload[]) {
		const url = this.baseUrl;
		try {
			await axios.post(url, events);
		} catch (error) {
			console.error('Error sending event:', error);
		}
	}

	// sends the events when the window is closed
	fireAtWindowClose() {
		const eventsSnapshot = this.eventsQueue.splice(0, this.eventsQueue.length);
		if (eventsSnapshot.length > 0) {
			this.sendEvent(eventsSnapshot);
		}

		const impressionsSnapshot = this.eventsQueue.splice(
			0,
			this.eventsQueue.length,
		);
		if (impressionsSnapshot.length > 0) {
			this.sendEvent(impressionsSnapshot);
		}
	}

	// initializes the timout function that calls sendEvent
	initializeQueue() {
		setInterval(() => {
			this.fireEventAfterInterval();
			this.fireImpressionAfterInterval();
		}, this.eventWindow);
	}

	/* FOR EVENTS */
	queueEvent<T extends CathodeEventsMetaDataTypes>(
		name: T,
		data: CathodeEventsMetaData[T],
	): void {
		const payload: CathodeEventPayload = {
			event_type: name,
			user: this.userObject,
			metadata: data,
		};
		this.collectEvents(payload);
	}

	collectEvents(event: CathodeEventPayload) {
		this.eventsQueue.push(event);
	}

	fireEventAfterInterval() {
		const eventsSnapshot = this.eventsQueue.splice(0, this.eventsQueue.length);
		if (eventsSnapshot.length > 0) {
			this.sendEvent(eventsSnapshot);
		}
	}

	/* FOR IMPRESSIONS */
	queueImpression<T extends CathodeImpressionsMetaDataTypes>(
		name: T,
		data: CathodeImpressionsMetaData,
	): void {
		const payload: CathodeImpressionsPayload = {
			event_type: name,
			user: this.userObject,
			metadata: data,
		};

		this.collectImpressions(payload);
	}

	collectImpressions(event: CathodeImpressionsPayload) {
		this.impressionsQueue.push(event);
	}

	fireImpressionAfterInterval() {
		const impressionsSnapshot = this.impressionsQueue.splice(
			0,
			this.impressionsQueue.length,
		);

		if (impressionsSnapshot.length > 0) {
			const groupedEvents: { [key: string]: CathodeImpressionsPayload } = {};

			impressionsSnapshot.forEach((event) => {
				// Create a key based on all properties except the data array
				const key = JSON.stringify({
					event_type: event.event_type,
					user: event.user,
					metadata: {
						source: event.metadata.source,
						page: event.metadata.page,
						type: event.metadata.type,
						time: event.metadata.time,
						data_source: event.metadata.data_source,
						clicked_from: event.metadata.clicked_from,
						collection_handle: event.metadata.collection_handle,
					},
				});

				if (!groupedEvents[key]) {
					// If the key doesn't exist, add the event to the dictionary
					groupedEvents[key] = {
						...event,
						metadata: {
							...event.metadata,
							data: [...event.metadata.data],
						},
					};
				} else {
					// If the key exists, merge the data arrays
					groupedEvents[key]!.metadata.data.push(...event.metadata.data);
				}
			});

			// Convert the dictionary back to an array
			this.sendEvent(Object.values(groupedEvents));
		}
	}
}
