import type { EventPayloads, EventTypes, LoginPayload } from './events';
import * as Sentry from '@sentry/vue';

export type InitArguments = {
	statsig: {
		sdkKey: string;
		countryCode: string | null;
		user: {
			userID: string | number;
			email: string;
		};
		environment: string;
	};
	moengage: {
		apiKey: string;
		cluster: string;
		debugLogs: boolean;
	};
	metapixel: {
		pixelId: string[];
	};
	gtag: {
		tagId: string;
	};
	omnisend: {
		brandId: string;
	};
	savyour: {
		authKey: string;
	};
};

export type Adapters = keyof InitArguments;
/**
 * Represents an adapter for sending events.
 */
export interface IAdapter {
	/**
	 * Initializes the adapter.
	 * This method is responsible for setting up client, at the right time, to start sending events.
	 * @returns A promise that resolves when the initialization is complete.
	 */
	name: string;
	init(args: InitArguments[Adapters]): Promise<void>;

	/**
	 * Sends an event with the specified name and data.
	 * @param name - The name of the event.
	 * @param data - The data associated with the event.
	 */
	sendEvent<T extends EventTypes>(name: T, data: EventPayloads[T]): void;
	login(data: LoginPayload): void;
	logout(): void;
}

export class EventClient {
	adapters: IAdapter[] = [];
	constructor() {}

	sendEvent<T extends EventTypes>(
		name: T,
		data: EventPayloads[T],
		disabledAdapters?: string[],
	) {
		const filteredAdapters = this.adapters.filter((adapter) => {
			if (disabledAdapters?.includes(adapter.name)) {
				return false;
			}
			return true;
		});
		for (let i = 0; i < filteredAdapters.length; i++) {
			const adapter = filteredAdapters[i]!;
			try {
				adapter.sendEvent(name, data);
			} catch (e: unknown) {
				console.log('Event service error', e);
				Sentry.captureException('Event service error', (scope) => {
					scope.setContext('data', {
						message:
							'Failed to send event for a single adapter, Details: ' +
							(e as Error).message,
						adapter: adapter.name,
						event: name,
						eventPayload: data,
					});
					scope.setLevel('error');
					return scope;
				});
				continue;
			}
		}
	}
	login(data: LoginPayload) {
		this.adapters.forEach((adapter) => {
			adapter.login(data);
		});
	}

	logout() {
		this.adapters.forEach((adapter) => {
			adapter.logout();
		});
	}

	setAdapters(adapters: IAdapter[]) {
		this.adapters = [...adapters];
	}
}
